import React, { useState, useEffect } from 'react';
import { getResume, getDevices } from '../processing/getData'; 
import moment from 'moment-timezone';
import banner from '../icons/actividad.png'; 
import './Activity.css';

const Activity = () => {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [vehicleFilter, setVehicleFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [resumeData, setResumeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicles = async () => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert('No hay user_id almacenado en localStorage');
        return;
      }

      try {
        const devicesData = await getDevices(userId);
        if (devicesData && devicesData.devices) {
          setVehicles(devicesData.devices);
          setFilteredVehicles(devicesData.devices); // Inicializa los vehículos filtrados
        }
      } catch (error) {
        console.error('Error al obtener los dispositivos:', error);
      }
    };

    fetchVehicles();
  }, []);

  const handleVehicleChange = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const handleVehicleFilterChange = (e) => {
    const filter = e.target.value;
    setVehicleFilter(filter);
    // Filtra los vehículos basados en el input
    const filtered = vehicles.filter(vehicle => 
      vehicle.plate.toLowerCase().includes(filter.toLowerCase())||vehicle.name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = async () => {
    setError(null);

    if (!selectedVehicle || !startDate || !endDate) {
      alert('Por favor, selecciona un vehículo y un rango de fechas.');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start >= end) {
      alert('La fecha de inicio debe ser menor que la fecha de fin.');
      return;
    }

    const diffHours = (end - start) / (1000 * 60 * 60);
    if (diffHours > 168) {
      alert('El rango de fechas no puede ser mayor a 7 días.');
      return;
    }

    setLoading(true);

    try {
      const adjustedStart = new Date(start.getTime() - (start.getTimezoneOffset() * 60 * 1000));
      const adjustedEnd = new Date(end.getTime() - (end.getTimezoneOffset() * 60 * 1000));
      const formattedStart = adjustedStart.toISOString().slice(0, 19).replace('T', ' ');
      const formattedEnd = adjustedEnd.toISOString().slice(0, 19).replace('T', ' ');
      const resume = await getResume(selectedVehicle, formattedStart, formattedEnd);
      if (resume && resume.resume) {
        setResumeData(resume.resume);
      } else {
        setError('No se encontraron datos para el rango especificado.');
      }
    } catch (error) {
      setError('Error al obtener el resumen.');
      console.error('Error al obtener el resumen:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="history-container">
      <img src={banner} alt="Banner" className="banner-history" />
      <div className="history-content">
        <div className="history-filters">
          <div className="date-inputs">
            <div className="date-input">
              <label htmlFor="start-date">Desde:</label>
              <input
                type="datetime-local"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="date-input">
              <label htmlFor="end-date">Hasta:</label>
              <input
                type="datetime-local"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="vehicle-select">
            <input
              type="text"
              placeholder="Filtrar..."
              value={vehicleFilter}
              onChange={handleVehicleFilterChange}
              className="vehicle-filter"
            />
            <select value={selectedVehicle} onChange={handleVehicleChange}>
              <option value="">Seleccionar</option>
              {filteredVehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.traccar_id}>
                  {vehicle.plate}
                </option>
              ))}
            </select>
            <button onClick={handleSubmit}>Consultar</button>
          </div>
        </div>

        {loading ? (
          <div className="loading-spinner">Cargando...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : resumeData ? (
          <div className="resume-table-container">
            <table className="resume-table">
              <tbody>
                <tr>
                  <th>Fecha de Inicio</th>
                  <td>{moment.utc(resumeData.date_init).tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th>Fecha de Fin</th>
                  <td>{moment.utc(resumeData.date_end).tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th>Distancia</th>
                  <td>{resumeData.distance + ' km'}</td>
                </tr>
                <tr>
                  <th>Horas del Motor</th>
                  <td>{resumeData.engineHours}</td>
                </tr>
                <tr>
                  <th>Horas de Conducción</th>
                  <td>{resumeData.conductionHours}</td>
                </tr>
                <tr>
                  <th>Velocidad Máxima</th>
                  <td>{resumeData.vmax + ' km/h'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Activity;
