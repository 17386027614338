// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("icons/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Cambia la URL a tu imagen de fondo */
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
  }
  
  .login-container input {
    width: 94%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-container button {
    padding: 10px 20px;
    background-color: rgb(7, 65, 126);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 20vh;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,8BAA8B;EAChC;;EAEA;IACE,yDAA6C,EAAE,uCAAuC;IACtF,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,uCAAuC;IACvC,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["body, html {\n    height: 100%;\n    margin: 0;\n    font-family: Arial, sans-serif;\n  }\n  \n  .background {\n    background-image: url('icons/background.png'); /* Cambia la URL a tu imagen de fondo */\n    background-size: cover;\n    background-position: center;\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .login-container {\n    background-color: rgba(255, 255, 255, 0.9);\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    width: 80%;\n    text-align: center;\n  }\n  \n  .login-container h2 {\n    margin-bottom: 20px;\n  }\n  \n  .login-container input {\n    width: 94%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .login-container button {\n    padding: 10px 20px;\n    background-color: rgb(7, 65, 126);\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    width: 20vh;\n  }\n  \n  .login-container button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
