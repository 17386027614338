export const getID = async (email) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/user/${email}`);
      const data = await response.json();
      return data;
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getDevices = async (user_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/devices/${user_id}`);
      const data = await response.json();
      return data;
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getLatestPosition = async (traccar_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/latestPosition/${traccar_id}`);
      const data = await response.json();
      return data;
      
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getPositionDevices = async (user_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/positionDevices/${user_id}`);
      const data = await response.json();
      return data;
      
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getHistory = async(traccar_id,date_init,date_end) =>
{
  try{
    const response = await fetch(`http://164.68.113.217:3821/api/history/${traccar_id}/${date_init}/${date_end}`);
    const data = await response.json();
    return data;
  }catch(error)
  {
    console.log('Failed to fetch data:', error);
    return null;
  }
}

export const getResume = async(traccar_id,date_init,date_end) =>
{
  try{
    const response = await fetch(`http://164.68.113.217:3821/api/resume/${traccar_id}/${date_init}/${date_end}`);
    const data = await response.json();
    return data;
  }catch(error)
  {
    console.log('Failed to fetch data:', error);
    return null;
  }
}