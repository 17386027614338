// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-company {
  margin-top: 5px;
  height: 50px;
  width: 250px;
  position: fixed;
  transform: translateX(-50%);
  left: 46%;
  top: 0;
}

  
  .companies-content 
  {
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    height: calc(100vh - 70px);
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 14px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Companies.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,2BAA2B;EAC3B,SAAS;EACT,MAAM;AACR;;;EAGE;;IAEE,eAAe;IACf,OAAO;IACP,SAAS;IACT,WAAW;IACX,0BAA0B;IAC1B,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".banner-company {\n  margin-top: 5px;\n  height: 50px;\n  width: 250px;\n  position: fixed;\n  transform: translateX(-50%);\n  left: 46%;\n  top: 0;\n}\n\n  \n  .companies-content \n  {\n    position: fixed;\n    left: 0;\n    top: 60px;\n    width: 100%;\n    height: calc(100vh - 70px);\n    padding-left: 15px;\n    padding-right: 15px;\n    box-sizing: border-box;\n    overflow: hidden;\n    font-size: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
