import React, { useState, useEffect } from 'react';
import banner from '../icons/empresas.png';
import './Companies.css'; 

const Companies = () => {
  return (
    <div className="companies-container">
      <img src={banner} alt="Banner" className="banner-company"/>
    </div>
  );
};

export default Companies;