import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Maps from './components/Maps';
import Menu from './components/Menu';
import Travel from './components/Travel';
import History from './components/History';
import Reports from './components/Reports';
import Companies from './components/Companies';
import Activity from './components/Activity';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setUserId(storedUserId);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (userId) => {
    setUserId(userId);
    setIsAuthenticated(true);
    localStorage.setItem('user_id', userId);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUserId(null);
    localStorage.removeItem('user_id');
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div>
          <Menu onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Maps />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/history" element={<History />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/companies" element={<Companies />} />
          </Routes>
        </div>
      )}
    </Router>
  );
};

export default App;
